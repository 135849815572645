import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserState } from 'redux/selectors';
import queryString from 'query-string';
import { getOwner } from 'services';
import * as helper from 'helpers';
import { useCreateDispatches } from 'helpers/hooks';

export default function useAcceptInviteState() {
	const { search } = useLocation();
	const { setUser } = useCreateDispatches();
	const user = useSelector(getUserState);
	let token = queryString.parse(search).token;
	const { userId } = helper.decodeToken(token);
	const [invalidToken, setInvalidToken] = useState(false);
	const [policyAccepted, setPolicyAccepted] = useState(false);

	const infoState = {
		username: helper.useFormInput('Username', ''),
		firstname: helper.useFormInput('First Name', '', { required: true }),
		lastname: helper.useFormInput('Last Name', '', { required: true }),
		mobile: helper.useFormInput('Phone', '', { type: 'phone' }),
		email: helper.useFormInput('Email', ''),
		newPassword: helper.useFormInput('Password', '', { required: true }),
		newPwConfirm: helper.useFormInput('Confirm Password', '', {
			required: true,
		}),
		token,
		invalidToken,
		user,
		policyAccepted,
		setPolicyAccepted,
	};

	const parseToken = useCallback(async () => {
		if (!token || !userId) {
			setInvalidToken(true);
		} else {
			try {
				let { data, response } = await getOwner(userId);
				if (data?.status === 'active') {
					setInvalidToken('Account is already active');
				}
				if (response && response.status !== 200) {
					setInvalidToken(data?.message || true);
					return;
				}

				setUser(data);
				infoState.email.onChange(helper.inputObject(data.email));
				infoState.username.onChange(helper.inputObject(data.email));
				infoState.firstname.onChange(helper.inputObject(data.firstname));
				infoState.lastname.onChange(helper.inputObject(data.lastname));

				helper.mixpanelSetUser({
					userId: data?.id,
					type: data?.type,
					role: data?.role,
					agencyId: data?.agencyId,
				});
			} catch (err) {
				setInvalidToken(true);
				return;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	useEffect(() => {
		parseToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return infoState;
}

import { useState } from 'react';
import { apiV2 as axios } from '../../../axios';
import { MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useCreateDispatches } from 'helpers/hooks';
import { updatePost } from 'services/post-services';
import * as service from 'services';
import {
	PrimaryButton,
	SecondaryButton,
	PasswordField,
	TextFieldRow,
} from 'components';

const CSWDeletePost = (props) => {
	const {
		showToggle,
		show,
		message,
		email: emailProp,
		onFailure,
		maxAttempts,
		getAttempts,
		setAttempts,
		keepLoader = true,
	} = props;
	const [input, setInput] = useState('');
	const [error, setError] = useState(false);
	const [deleteReason, setDeleteReason] = useState('');
	const { user } = useSelector(({ user }) => user);
	const { setLoader, setSnackbar, removePost, setChildPendCount } =
		useCreateDispatches();
	const post = useSelector((state) => state.posts.post);
	const childPending = useSelector(
		(state) => state.lifebooks.child.pendingPostCount
	);

	const deleteReasons = {
		courtRequest: 'Court Request',
		legalReasons: 'Legal Reasons',
		userError: 'User Error',
	};

	const deleteObjectMap = () => {
		let array = [];
		for (let [key, value] of Object.entries(deleteReasons)) {
			array.push({ value: key, label: value });
		}
		return array;
	};

	const deleteReasonsMap = deleteObjectMap().map((val) => {
		return (
			<MenuItem key={val.value} value={val.value}>
				{val.label}
			</MenuItem>
		);
	});

	const handleDelete = async () => {
		let message = 'Post deleted successfully';
		let id = post.id;
		let childId = post.childId;

		service
			.archivePost(id)
			.then(() => {
				setSnackbar({
					variant: 'success',
					message: `${message}`,
				});
				removePost({ id });
				service.getCurrentChild(childId, { fetch: true });
				if (post.status !== 'approved') {
					setChildPendCount({ childId, count: childPending - 1 });
				}
			})
			.catch((err) => {
				setSnackbar(
					{ variant: 'error', message: err.response?.data.message },
					{ log: true }
				);
			})
			.finally(() => {
				setLoader(false);
			});
	};

	const deletePost = async () => {
		const cswPost = {
			deleteReason: deleteReason,
			postId: post.id,
			nspId: post.nspId ? post.nspId : null,
		};
		updatePost(cswPost).then(() => handleDelete());
	};

	const handleSubmit = () => {
		setLoader(true);
		const { email: userEmail } = user;
		axios
			.post('/session/authenticate', {
				email: emailProp || userEmail,
				password: input,
			})
			.then(async (res) => {
				let token = res.data.token;
				localStorage.setItem('token', token);
				await deletePost(deleteReason);
				showToggle(false);
			})
			.catch((err) => {
				if (onFailure) {
					if (!maxAttempts || !getAttempts || !setAttempts) return onFailure();

					const attempts = getAttempts();
					if (attempts >= maxAttempts) {
						onFailure();
					} else {
						setAttempts(attempts + 1);
						const attemptsMessage = `. Remaining attempts: ${
							maxAttempts - attempts
						}`;
						setError(`Invalid password${attemptsMessage}`);
					}
				} else {
					setError('Invalid password');
				}
			})
			.finally(() => {
				keepLoader && setLoader(false);
			});
	};

	const enterSubmit = (e) => {
		if (e.keyCode === 13) {
			handleSubmit();
		}
	};

	return (
		<div
			className={`password-confirm-container ${
				!show ? 'password-confirm-hide' : ''
			}`}>
			<div className='password-confirm-card'>
				<h3>
					{message ? message : 'Please confirm your password to continue'}
				</h3>
				<div style={{ width: '90%' }}>
					<TextFieldRow
						value={deleteReason}
						label='Reason for deleting'
						name='cswDeleteReason'
						onChange={(e) => setDeleteReason(e.target.value)}
						select>
						{deleteReasonsMap}
					</TextFieldRow>
				</div>
				<PasswordField
					label='Password'
					onChange={(e) => {
						setInput(e.target.value);
					}}
					autoFocus={true}
					value={input}
					style={{ width: '85%' }}
					onKeyDown={(e) => enterSubmit(e)}
					inputProps={{ autoComplete: 'new-password' }}
				/>
				<div className='password-confirm-error'>{error || null}</div>
				<div className='password-confirm-button-container'>
					<SecondaryButton onClick={() => showToggle(false)}>
						Cancel
					</SecondaryButton>
					<PrimaryButton onClick={() => handleSubmit()}>Submit</PrimaryButton>
				</div>
			</div>
		</div>
	);
};

export default CSWDeletePost;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, Switch, Route } from 'react-router-dom';
import { getAgencyManagerUsers } from 'redux/selectors';
import * as services from 'services';
import * as helper from 'helpers';
import AdminListContainer from './components/AdminListContainer';
import ManageAdmin from './components/ManageAdmin';
import { SendInvites, Welcome } from 'components';
import styles from './administrators.module.css';

function Administrators(props) {
	const {
		match: {
			params: { adminId },
		},
	} = props;

	const managerList = useSelector(getAgencyManagerUsers);

	const [state, setState] = helper.useSetState({
		invite: false,
	});

	const setInvite = (val) => setState({ invite: val });

	helper.useMobileListCheck(!(adminId || state.invite));

	const getList = async () => {
		await services.getAgencyUsers();
	};

	useEffect(() => {
		getList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.adminFullContainer}>
			<AdminListContainer setInvite={setInvite} />
			<div className={styles.adminContent}>
				<Switch>
					<Route path='/admins/manage/:adminId?'>
						<ManageAdmin key={adminId} />
					</Route>
					<Route path='/' component={Welcome} />
				</Switch>
				{state.invite ? (
					<div className={styles.adminContainer}>
						<SendInvites
							onClose={() => setInvite(false)}
							type={state.invite.type}
							getList={getList}
							managerList={
								helper.experiments.newInvite(true) ? managerList : []
							}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
}

export default withRouter(Administrators);

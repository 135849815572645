import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/reducers';
import { MenuItem } from '@material-ui/core';
import { TextFieldRow } from 'components';
import { makeStyles } from '@material-ui/core/styles';

const roleSelectorStyles = () => ({
	roleSelectorTextField: {
		margin: '16px 0',
	},
});

const useStyles = makeStyles(roleSelectorStyles);

export const InviteRoleSelector = ({ role, onChange, disableMessage }) => {
	const userRole = useSelector((state) => state.user.user.role);
	const [managerDisabled, setManagerDisabled] = useState(false);
	const [adminDisabled, setAdminDisabled] = useState(false);

	const classes = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		if (userRole === 'manager') {
			setAdminDisabled(true);
		}
		if (userRole === 'social-worker') {
			setAdminDisabled(true);
			setManagerDisabled(true);
		}
	}, [userRole]);

	const roleList = [
		{ value: 'social-worker', label: 'Social Worker', disabled: false },
		{ value: 'manager', label: 'Manager', disabled: managerDisabled },
		{ value: 'admin', label: 'Admin', disabled: adminDisabled },
	];

	const roleListMap = roleList.map((val) => {
		return val ? (
			<MenuItem key={val.value} value={val.value} disabled={val.disabled}>
				{val.label}
			</MenuItem>
		) : null;
	});

	const handleRoleChange = (e) => {
		const { value } = e?.target || {};
		onChange(value);
	};

	const handleClick = () => {
		!!disableMessage &&
			dispatch(
				setSnackbar({
					variant: 'error',
					message: disableMessage,
				})
			);
	};

	return (
		<TextFieldRow
			select
			className={classes.roleSelectorTextField}
			label='Role'
			value={role}
			disabled={!!disableMessage}
			onChange={handleRoleChange}
			onClick={handleClick}>
			{roleListMap}
		</TextFieldRow>
	);
};

export default InviteRoleSelector;

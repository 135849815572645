import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMobileList } from 'redux/index';
import { IndicatorCount, Avatar } from 'components';
import styles from '../userList.module.css';

function UserListTile(props) {
	const { value, select, children, pathParam, indicatorCount, noAvatar } =
		props;

	const dispatch = useDispatch();

	const onSelect = (e) => {
		dispatch(setMobileList(false));
		select(e, value);
	};

	const selectNull = () => {
		return null;
	};
	const selectable = Boolean(select);
	const activeClass =
		pathParam === value?.id ? styles.selectListTileActive : '';

	const alt = (typeof children === 'string' ? children : children?.[0]) || '';
	const avatar = (
		<Avatar
			image={value?.photo?.url || value?.photoUrl}
			alt={alt}
			size='small'
		/>
	);

	return children || children?.[0] ? (
		<React.Fragment>
			<div
				className={`${styles.selectListTile} ${
					selectable ? styles.selectListTileSelectable : ''
				} ${activeClass}`}
				onClick={selectable ? onSelect : selectNull}>
				{!noAvatar ? (
					<div>{avatar}</div>
				) : (
					<div className={styles.selectListTileNoAvatar} />
				)}
				{value.status === 'invited' ? (
					<div className={styles.selectListTileNameInactive}>{children}</div>
				) : (
					<div className={styles.selectListTileName}>{children}</div>
				)}
				{indicatorCount ? (
					<IndicatorCount className={styles.selectListTileIndicator}>
						{indicatorCount}
					</IndicatorCount>
				) : null}
			</div>
		</React.Fragment>
	) : null;
}

export default withRouter(UserListTile);

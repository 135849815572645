import { useSelector } from 'react-redux';
import { getUserState, getPostsFeedType, getPostsRoot } from 'redux/selectors';
import { useCheckUserPermissions } from './useCheckUserPermissions';

const checkDateDayOld = (date) => {
	const oneDay = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
	return new Date(date).getTime() > oneDay;
};

export function useCheckPostPermissions({
	id: postId,
	status,
	createdAt,
	variant,
	createdBy = {},
	nsp,
	changeRequestMessage,
	displayNSPInTimeline,
} = {}) {
	const feedType = useSelector(getPostsFeedType);
	const root = useSelector(getPostsRoot);
	const { includeUsers } = useCheckUserPermissions();
	const { id: userId, type, partner, role } = useSelector(getUserState);
	const isApproved = status === 'approved';
	const isVault = variant === 'vault' || root === 'vault';
	const isDraft = postId ? false : true;
	const isDayOld = checkDateDayOld(createdAt); // post is less than a day old
	const isOwnPost = createdBy.id === userId;
	const isChangeRequested =
		status === 'change_requested' && changeRequestMessage;
	const child = useSelector((state) => state.lifebooks.child);
	const casaPermissions = useSelector((state) => state.casa.casaPermissions);
	const casaUserPermissions = useSelector(
		(state) => state.casa.casaUserPermissions
	);

	let permissions;

	if (type === 'casauser') {
		if (role === 'admin') {
			permissions = casaPermissions;
		} else {
			permissions = casaUserPermissions;
		}
	}

	const noCasaPermission =
		type === 'casauser' && child.agencyId && !permissions?.mgmt_lifebook;

	const allowEdit = () => {
		// NOTE: Allowed to edit
		// - agency: timeline/service/vault posts at any time
		// - contrib: timeline/service posts if !isApproved
		// - child:
		// 			- other's timeline posts if !isApproved
		//			- own posts within 24 hours

		if (feedType === 'timeline' && displayNSPInTimeline) return false;
		if (isDraft) return true;
		if (noCasaPermission) return false;

		switch (type) {
			case 'casauser':
			case 'agencyuser':
				return true;
			case 'contributor':
				if (isVault) return false;
				return isOwnPost && !isApproved;
			case 'child':
				if (isVault || nsp) return false;
				return isOwnPost && isDayOld;
			default:
				return false;
		}
	};

	const allowCreateChangeRequest = () => {
		// NOTE: allowed to request a change
		// - vault: Never
		// - agency: timeline/service posts if !isApproved
		// - contrib: never
		// - child:
		//      - other's timeline posts if isApproved
		//      - own post never

		if (isVault) return false;
		if (isDraft) return false;
		if (noCasaPermission) return false;
		switch (type) {
			case 'casauser':
			case 'agencyuser':
				return !isApproved;
			case 'contributor':
				return false;
			case 'child':
				// if (isVault || nsp) return false;
				// return isApproved;
				return false;
			default:
				return false;
		}
	};

	const showChangeRequestMessage = () => {
		// NOTE: display the change request message
		// - agency: !isApproved && has changeRequestMessage
		// - contributor: !isOwnPost && has changeRequestMessage
		// - child:
		if (!isChangeRequested) return false;
		if (noCasaPermission) return false;

		switch (type) {
			case 'casauser':
			case 'agencyuser':
				return !isApproved;
			// case 'child':
			// 	return isApproved;
			default:
				return isOwnPost;
		}
	};

	const allowApprove = () => {
		// NOTE: allowed to request a change
		// - only agency
		// - !isApproved
		if (isDraft) return false;
		if (noCasaPermission) return false;
		return includeUsers('agencyuser', 'casauser') && !isApproved;
	};

	const allowDelete = () => {
		// NOTE: allowed to request a change
		// - only for own post
		// - child limited to 24hrs
		// if family connect read only return false;
		// if (includeUsers('agencyuser')) return true;
		// if (includeUsers('child') && isDayOld) return true;
		// if (!isOwnPost) return false;
		// return false;

		if (feedType === 'timeline' && displayNSPInTimeline) {
			return false;
		}

		if (isDraft) return true;
		if (noCasaPermission) return false;

		switch (true) {
			case !userId:
			case partner === 'ConnectOurKids':
				return false;
			case type === 'child' && variant === 'vault':
				return false;
			case type === 'agencyuser':
			case type === 'child':
			case type === 'casauser':
			case type === 'csw':
				return true;
			default:
				return isOwnPost && !isApproved;
		}
	};

	const allowSendToVault = () => {
		// NOTE: allowed to send post to vault
		// - Only agencyuser and csw
		if (feedType === 'timeline' && displayNSPInTimeline) {
			return false;
		}

		if (isDraft) return false;

		if (variant === 'vault') return false;
		if (!postId) return false;
		switch (type) {
			case 'agencyuser':
			case 'csw':
				return true;
			default:
				return false;
		}
	};

	const moveFromVault = () => {
		if (variant === 'vault') {
			return true;
		}
		return false;
	};

	const allowShowVaultOption = () => {
		if (status === 'pending') return false;

		if (isDraft) return false;

		if (root === 'vault' || isApproved) {
			return false;
		}

		if (includeUsers('agencyuser', 'csw')) {
			return true;
		}

		return false;
	};

	const allowMoveServicePost = () => {
		if (isDraft) return false;

		if (type === 'child') {
			if (feedType === 'services') {
				if (!displayNSPInTimeline) {
					return true;
				} else {
					return false;
				}
			}

			if (feedType === 'timeline') {
				if (displayNSPInTimeline) {
					return true;
				} else {
					return false;
				}
			}
		}

		return false;
	};

	const allowDraft = () => {
		switch (true) {
			case root === 'vault':
			case isVault:
				return false;
			default:
				return true;
		}
	};

	return {
		allowEdit,
		allowCreateChangeRequest,
		showChangeRequestMessage,
		allowApprove,
		allowDelete,
		allowSendToVault,
		moveFromVault,
		allowShowVaultOption,
		allowMoveServicePost,
		allowDraft,
	};
}

import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { includeRole } from 'helpers';
import { useCheckUserPermissions } from 'helpers/hooks';
import ListSort from './components/ListSort';
import HomeTile from './components/HomeTile';
import UserListTile from './components/UserListTile';
import UserListSearch from './components/UserListSearch';
import UserListEmpty from './components/UserListEmpty';
import styles from './userList.module.css';

function UserList(props) {
	const {
		list = [],
		label,
		handleSelect,
		addToggle,
		addLabel,
		pathParam,
		children,
		sortMenuItems,
		filterMenuItems,
		inviteItems,
	} = props;
	const params = useParams();
	const houseHash = useSelector((state) => state.homes.houseHash);
	const sortType = useSelector((state) => state.lifebooks.childSort);
	const { includeUsers } = useCheckUserPermissions();
	const location = useLocation();

	const [searchFilteredList, setSearchFilteredList] = useState([...list]);
	useEffect(() => {
		if (list) {
			setSearchFilteredList([...list]);
		}
	}, [list]);

	const getFirstTileName = (val) => {
		const house = houseHash && houseHash[val.house?.id];
		switch (sortType) {
			case 'house':
				return house ? `${house.name} Home` : 'Unassigned';

			default:
				return null;
		}
	};

	const listMap =
		searchFilteredList && Boolean(searchFilteredList.length)
			? searchFilteredList.map((val, i, arr) => {
					const firstTile =
						sortType === 'house' && val.house?.id !== arr[i - 1]?.house?.id;

					let firstTileName = firstTile ? getFirstTileName(val) : null;

					return val ? (
						<React.Fragment key={`${val.id}${val.name}`}>
							{firstTile && <HomeTile name={firstTileName} />}
							<UserListTile
								value={val}
								indicatorCount={
									location?.pathname?.includes('vault') ||
									location?.pathname?.includes('calendar')
										? null
										: val.pendingPostCount
								}
								pathParam={params[pathParam] || props.paramId}
								select={handleSelect}>
								{`${val.displayName || val.name}`}
								{val.connectId && val.status === 'pending' && ' (pending)'}
								{val.privileged && (
									<img
										style={{
											width: '15px',
											height: '15px',
											marginLeft: '3%',
										}}
										src='/assets/crown-icon.svg'
										alt='Ella Elephant'
									/>
								)}
							</UserListTile>
						</React.Fragment>
					) : null;
			  })
			: null;

	return (
		<div className={styles.userListContainer}>
			<ListSort
				sortMenuItems={sortMenuItems}
				filterMenuItems={filterMenuItems}
				addToggle={
					includeRole(['admin', 'manager', 'privileged', 'casaadmin']) &&
					addToggle
				}
				addHover={addLabel}
				label={label}
				value={sortType}
				list={list}
				inviteItems={inviteItems}
			/>
			{includeUsers('agencyuser', 'csw', 'casauser') && (
				<UserListSearch
					list={list}
					setFilteredList={setSearchFilteredList}
					searchableProperties={['house.name']}
				/>
			)}
			<div className={styles.userListTileMap}>
				{children}
				{listMap?.length > 0 ? listMap : <UserListEmpty />}
			</div>
		</div>
	);
}

export default UserList;

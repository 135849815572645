import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAgencyUser } from 'redux/selectors';
import * as service from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { PasswordConfirm, FormSectionHeader, PrimaryButton } from 'components';
import styles from '../socialWorkers.module.css';

export default function EnableSwPrivilege() {
	const { swId } = useParams();
	const { id, privileged } = useSelector(getAgencyUser(swId));
	const { setModal, setLoader, setSnackbar } = useCreateDispatches();

	const onSubmit = async () => {
		const requestFunction = privileged
			? service.disableSWPrivilege
			: service.enableSWPrivilege;
		await requestFunction(id)
			.then(() => {
				setSnackbar({
					variant: 'success',
					message: 'Permissions successfully changed.',
				});
			})
			.catch((err) => {
				setSnackbar(
					{ variant: 'error', message: err.response?.data.message },
					{ log: true }
				);
			})
			.finally(() => {
				service.getAgencyUsers();
				setLoader(false);
			});
	};

	const onConfirm = (onSubmit) => {
		setModal(
			<PasswordConfirm
				show={true}
				showToggle={(val) => setModal(val)}
				onSubmit={onSubmit}
			/>
		);
	};

	return (
		<div className={styles.enableSwPrivilegeRoot}>
			<div className={styles.enableSwPrivilegeContent}>
				<FormSectionHeader hideTop>Management Options</FormSectionHeader>
			</div>
			<div className={styles.enableSwPrivilegeItem}>
				<div>
					<div className={styles.enableSwPrivilegeLabel}>
						Enable Extended Permissions
						<img
							style={{
								width: '15px',
								height: '15px',
								marginLeft: '1%',
							}}
							src='/assets/crown-icon.svg'
							alt='Ella Elephant'
						/>
					</div>
					<div>
						Enabling extended permissions will allow This User access to the
						following management options: Access to all the children in your
						agency/Access Archive Ability to Add a child/Edit Child information
						and Exit Care for a child Ability to Add a Household/ Add and Edit
						Resource Parent information.
					</div>
				</div>
				<div className={styles.enableSwPrivilegeButton}>
					<PrimaryButton onClick={() => onConfirm(onSubmit)}>
						{privileged ? 'Disable' : 'Enable'}
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
}
